$open-sans-light: 300 12px 'Open Sans Light', sans-serif;
$open-sans-regular: 400 12px 'Open Sans Regular', sans-serif;
$open-sans-semibold: 600 12px 'Open Sans Semi-Bold', sans-serif;
$open-sans-bold: bold 12px 'Open Sans Bold', sans-serif;

@font-face {
  font-family: 'Gotham-BookItalic';
  src: url('../fonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Gotham-BookItalic.woff') format('woff'), 
       url('../fonts/Gotham-BookItalic.ttf')  format('truetype'), 
       url('../fonts/Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Gotham-Bold.woff') format('woff'), 
       url('../fonts/Gotham-Bold.ttf')  format('truetype'),
       url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Black';
  src: url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gotham-Black.woff') format('woff'),
       url('../fonts/Gotham-Black.ttf')  format('truetype'),
       url('../fonts/Gotham-Black.svg#Gotham-Black') format('svg');
  font-weight: normal;
  font-style: normal
}


@font-face {
  font-family: 'Gotham-Medium';
  src: url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gotham-Medium.woff') format('woff'),
       url('../fonts/Gotham-Medium.ttf')  format('truetype'),
       url('../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Gotham-Book.woff') format('woff'),
       url('../fonts/Gotham-Book.ttf')  format('truetype'),
       url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}
