@import "variables";
.search-overlay {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, .95);
  z-index: 20;
  .glyphicon.glyphicon-remove {
    color: #1b2c40;
    font-size: 25px;
  }
  >.container {
    margin-bottom: 25px;
    margin-top: 25px;
    .input-wrapper {
      border-bottom: 2px solid black;
      color: #1b2c40;
      margin-top: 35px;
      &:before {
        background: #000;
        bottom: 0;
        content: "";
        display: block;
        height: 6px;
        left: 15px;
        position: absolute;
        width: 2px;
      }
      &:after {
        background: #000;
        bottom: 0;
        content: "";
        display: block;
        height: 6px;
        position: absolute;
        right: 15px;
        width: 2px;
      }
      >input {
        background-color: inherit;
        border: none;
        font-size: 16px;
        padding: 0 15px;
        width: 100%;
        font: normal 24px "Gotham-Medium";
        color: #014459;
        &:active,
        &:focus,
        &hover {
          outline: none;
          border: none;
        }
      }
    }
    .results-container {
      font-family: 'Gotham-Book';
      margin-bottom: 25px;
      margin-top: 25px;
      .count {
        margin-bottom: 25px;
        margin-top: 20px;
        >span {
          color: #014459;
          font-family: 'Gotham-Bold';
          white-space: pre-wrap;
        }
      }
      .results {
        height: calc(100vh - 200px);
        overflow-y: auto;
        .result {
          margin-bottom: 20px;
          &:before {
            background: #e5e2e2;
            content: "";
            display: block;
            height: 1px;
            width: 100%;
          }
          h3 {
            font-family: 'Gotham-Bold';
            a {
              color: #014459;
            }
          }
        }
      }
    }
  }
}
