@mixin center-with-absolute {
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

$navbar-height: 60px;

$hamburger-color: #825e37;
$yellow-color: #f4cd49;
$chat-background: #003d50;
$chat-operator-background: #336473;
$chat-typing-background: #022935;
$chat-send-color: #00b8f0;
$chat-overlay-color: rgba(51, 100, 115, 0.8);
$chat-send-rating-color: #f4cd49;
$chat-send-rating-hover-color: #e0bd39;

$thick-border: 10px solid $yellow-color;