section {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    color: #fff;
    max-width: 599px; 
    padding: 37px;
    strong:first-child {
        font: normal 22px 'Gotham-Book';
    }
    h4 {
        font: normal 20px 'Gotham-Bold';
        margin: 37px 0 18px;
    }
    p {
        font: normal 16px 'Gotham-Book';
        margin: 0;
    }
    hr {
        border-color: #414243;
        margin: 36px 0;
    }
    .icon {
        background: url('../img/gallery_sprite.png') 0 0 no-repeat;
    }
    @import "../common/like_dislike_button";
    div.like {
        justify-content: baseline;
        flex-wrap: wrap;
        strong:first-child {
            width: 100%;
            font: normal 14px 'Gotham-Book';
            margin: 0 0 27px;
        }
        ul {
            .icon {
                &.like {
                    background-position: 0 0;
                    width: 42px;
                    height: 50px;
                }
                &.unlike {
                    background-position: 0 -52px;
                    width: 42px;
                    height: 50px;
                }
            }
            li {
                strong {
                    span {
                        background: url("../img/gallery_sprite.png") -61px 0 no-repeat;
                        width: auto;
                        height: 32px;
                        line-height: 32px;
                        &.left {
                            background-position: -45px 0;
                            width: 15px;
                        }
                        &.right {
                            background-position: -112px 0;
                            width: 7px;
                        }
                    }
                    &.unlike-bubble {
                        color: #fff;
                        span {
                            background-position: -139px 0;
                            &.left {
                                background-position: -124px 0;
                            }
                            &.right {
                                background-position: -192px 0;
                            }
                        }

                    }
                }
            }
        }
    }
    @import "../common/facebook_share";
    div.share {
        strong {
            color: #fff;
            span {
                background: url("../img/gallery_sprite.png") -139px 0 no-repeat;
                width: auto;
                height: 32px;
                line-height: 32px;
                &.left {
                    background-position: -124px 0;
                    width: 15px;
                }
                &.right {
                    background-position: -192px 0;
                    width: 7px;
                }
            }
        }
        i {
            font: normal 14px 'Gotham-Book';
            flex-grow: 0;
            text-align: left;
            width: 285px;
            margin: 0 0 0 37px;
        }
    }
}