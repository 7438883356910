@import 'variables';
@import 'fonts';
.row.tiles {
  background-color: #FFFCF2;
  box-shadow: #c6c6c6 0 0 5px 5px;
  >.col-sm-6.col-md-4.col-xs-12 {
    padding: 1px;
    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.tile.front {
  color: white;
  &.with-img {
    background-color: rgba(255, 255, 255, .8);
    color: black;
    .tile-title {
      background-color: rgba(255, 255, 255, .8);
    }
  }
  .tile-title {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    height: auto;
    @media (max-width: 400px) {
      margin: 0 10px 10px 10px;
      padding: 10px;
    }
    @media (min-width: 401px) {
      margin: 0 15px 15px 15px;
      padding: 15px;
    }
  }
}

.adult {
  .tile.front,
  .tile.back {
    color: #fff;
    .tile-title {
      color: #fff;
    }
  }
  .tile.front.with-img {
    .tile-title {
      color: #000;
    }
  }
}

.tile-title:hover+.short-desc {
  opacity: 1;
  transition: all 0.3s linear;
  visibility: visible;
}

.clickable {
  cursor: pointer;
}

md-grid-tile {
  /*&.embedded {

    &[data-tile-type="tile1"] .tile.front .tile-title, &[data-tile-type="tile1"] .tile h4 {
      font-size: 8px;
    }
    &[data-tile-type="tile2"] .tile.front .tile-title, &[data-tile-type="tile3"] .tile.front .tile-title, &[data-tile-type="tile2"] .tile h4, &[data-tile-type="tile3"] .tile h4 {
      font-size: 10px;
    }
    &[data-tile-type="tile4"] .tile.front .tile-title, &[data-tile-type="tile4"] .tile h4 {
      font-size: 14px;
    }
  }*/
  &[data-tile-type="tile2"] .tile.front .tile-title {
    font-size: 20px;
  }
  &[data-tile-type="tile3"] .tile.front .tile-title {
    font-size: 16px;
  }
  &[data-tile-type="tile4"] .tile.front .tile-title {
    font-size: 28px;
  }
  @media screen and (min-width: 401px) and (max-width: 1200px) {
    &[data-tile-type="tile3"] .tile.front .tile-title {
      font-size: 16px !important;
    }
  }
  @media screen and (max-width: 400px) {
    &[data-tile-type="tile1"] .tile.front .tile-title {
      font-size: 13px !important;
    }
    &[data-tile-type="tile2"] .tile.front .tile-title {
      font-size: 14px !important;
    }
    &[data-tile-type="tile3"] .tile.front .tile-title {
      font-size: 15px !important;
    }
    &[data-tile-type="tile4"] .tile.front .tile-title {
      font-size: 16px !important;
    }
  }
}

.tile {
  color: white;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  .type-icon-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 22px;
    height: 44px;
    margin-left: -15px;
    margin-top: -15px;
    width: 44px;
    >* {
      height: 44px;
      padding: 11px;
      width: 44px;
    }
    >span {
      font-weight: bold;
      font-size: 20px;
      padding: 5px;
      vertical-align: middle;
    }
  }
  .age-indicator {
    font-size: 18px;
    font-weight: bold;
    height: 44px;
    left: 44px;
    padding: 11px 0;
    position: absolute;
    top: 0;
    width: 44px;
  }
}

.follow-tile {
  width: 100%;
  height: 100%;
}

.flipper {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  .front,
  .back {
    float: left;
    display: block;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    transition: transform ease 500ms;
  }
  .front {
    z-index: 2;
    background: inherit;
    /* front tile styles go here! */
  }
  .front {
    z-index: 2;
    /* front tile styles go here! */
  }
}

.back {
  .btn-sm.round {
    border-radius: 26px;
  }
  .btn.outline {
    background: none;
    padding: 6px 11px;
  }
  .btn-primary.outline {
    border: 2px solid #ffffff;
    bottom: 15px;
    color: #ffffff;
    position: absolute;
    right: 15px;
  }
  .btn-primary.outline:hover,
  .btn-primary.outline:focus,
  .btn-primary.outline:active,
  .btn-primary.outline.active,
  .open>.dropdown-toggle.btn-primary {
    color: #33a6cc;
    border-color: #33a6cc;
  }
  .btn-primary.outline:active,
  .btn-primary.outline.active {
    border-color: #007299;
    color: #007299;
    box-shadow: none;
  }
  /* back tile styles go here! */
}

md-grid-tile:not(.clickable) {
  .tile-title, .tile:hover {
    cursor: default !important;
  }
}

@media screen and (min-width: 1024px) {
  .front {
    transform: rotateY(0deg);
  }
  .back {
    transform: rotateY(-180deg);
  }
}

@media screen and (min-width: 1024px) {
  .flipped {
    .front {
      transform: rotateY(180deg);
    }
    .back {
      transform: rotateY(0deg);
    }
  }
}