@import "fonts";
@import "variables";
@media screen and (max-width: 925px) {
  body {
    transition: transform .6s;
    .wrapper .mobile-overlay {
      opacity: 0;
      transition: opacity .6s;
    }
    .navbar-yelon .navbar-header button.navbar-toggle>span.icon-bar {
      transition: transform .6s, opacity .6s;
    }
    &.menu-active {
      transform: translateX(-75%);
      .wrapper .mobile-overlay {
        background-color: rgba(0, 0, 0, 0.6);
        bottom: 0;
        box-shadow: black 0 0 20px 10px inset;
        height: calc(100% - #{$navbar-height});
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        top: $navbar-height;
        width: 100%;
        z-index: 2;
      }
      .navbar-yelon .navbar-header button.navbar-toggle>span.icon-bar {
        &.before {
          transform: translateY(6px) rotate(-45deg);
        }
        &.main {
          opacity: 0;
        }
        &.after {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
    .navbar-yelon .container-fluid {
      padding: 0 !important;
    }
    .navbar-header {
      width: 100% !important;
      margin: 0 !important;
      nav {
        display: none !important;
      }
    }
    .navbar-toggle {
      display: block !important;
      margin-right: 0 !important;
    }
    #yelon-navbar.collapse {
      display: none !important;
    }
    &.adult {
      .navbar-yelon p {
        display: none;
      }
    }
    .search-overlay {
      display: none;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .tab {
    display: none !important;
  }
  #mobile-menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 1;
    width: 75%;
    overflow-y: scroll;
    height: 100%;
    display: block !important;
    /*.bg-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.8);
    }*/
    header {
      background: #f4cd49;
      position: relative;
      z-index: 2;
      height: $navbar-height;
      display: flex;
      a {
        background: #fff;
        width: 17%;
        height: 93%;
        align-self: flex-end;
        span {
          font-size: 28px;
          color: #825e37;
          margin: 16px 0 0 22px;
        }
      }
      div {
        width: calc(100% - 34px);
        margin: 0 17px 11px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.6);
        display: flex;
        .vline {
          width: 1px;
          height: 7px;
          background: rgba(255, 255, 255, 0.6);
          align-self: flex-end;
        }
        input {
          border: none;
          background: transparent;
          padding: 0;
          flex-grow: 1;
          align-self: flex-end;
          font-size: 13px;
          color: #fff;
        }
        .fa {
          align-self: flex-end;
          color: #fff;
          font-size: 28px;
          margin: 0 6px 5px 0;
        }
      }
    }
    section {
      z-index: 2;
      background: #fff;
      width: 100%;
      height: calc(100% - 60px);
      position: relative;
      right: 0;
      .filter {
        position: relative;
        padding: 16px 0 0;
        background: #fff;
        h5 {
          margin: 12px 0 15px 0;
          padding: 0 15px;
          font: normal 12px 'Gotham-Bold';
          color: #000;
          &:first-of-type {
            margin: 0 0 15px;
          }
        }
        .remove-filter {
          position: absolute;
          top: 19px;
          right: 17px;
          color: #acacac;
          font: normal 9px 'Gotham-Bold';
          text-decoration: none;
          span {
            margin: 0 0 0 3px;
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          padding: 0 15px;
          li {
            color: #fff;
            margin: 0 4px 3px 0;
            button {
              border-radius: 2px;
              border: none;
              background: #acacac;
              padding: 6px;
              font: normal 10px 'Gotham-Bold';
            }
            &.active {
              button {
                background: $yellow-color;
              }
            }
          }
        }
        p {
          padding: 0 15px 16px;
          font: normal 10px 'Gotham-Book';
          color: #010101;
          margin: 13px 0 0;
        }
        .btn {
          margin: 0 15px;
          width: calc(#{100%} - 30px);
          border-radius: 2px;
          background: #f4cd49;
          font: normal 14px 'Gotham-Bold';
          color: #fff;
        }
      }
      nav {
        padding: 30px 15px 27px;
        border: solid 1px #e5e5e5;
        border-right: none;
        border-left: none;
        background: #fff;
        li {
          font: normal 14px 'Gotham-Bold';
          margin: 0 0 15px;
          &:last-child {
            margin: 0;
          }
          a {
            text-decoration: none;
            color: #000;
          }
        }
      }
      .parent-link {
        padding: 10px 15px;
        color: #000;
        font: normal 14px 'Gotham-Bold';
        background: #fff;
        display: block;
        text-decoration: none;
      }
      footer {
        background: #f4cd49;
        width: 100%;
        height: 53px;
        position: absolute;
        bottom: 0;
        .icon {
          background: url('../img/mobile_sprite.png') 0 0;
          width: 0;
          height: 19px;
          display: inline-block;
        }
        ul {
          display: flex;
          padding: 0 10px;
          font: normal 10px 'Gotham-Book';
          height: 100%;
          align-items: center;
          color: #fff;
          li {
            margin: 0 10px;
            &:last-child {
              margin: 0;
              flex-grow: 1;
              text-align: right;
            }
          }
          .icon {
            &.facebook {
              width: 12px;
            }
            &.instagram {
              width: 18px;
              background-position: -28px 0;
            }
            &.twitter {
              width: 18px;
              background-position: -63px 0;
            }
          }
        }
      }
    }
  }
}