@import "fonts";
@import "variables";
#faq {
    background: #fff;
    box-shadow: #c6c6c6 0 0 5px 5px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    header {
        background: #004a60;
        color: #fff;
        text-align: center;
        padding: 50px 0 57px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
            text-transform: uppercase;
            margin: 0;
            font: normal 28px 'Gotham-Bold';
        }
        p {
            margin: 18px 70px 0;
            font: normal 16px 'Gotham-Book';
        }
    }
    #content {
        padding: 37px 84px 100px 79px;
        div {
            display: flex;
            padding: 0 0 19px;
            border-bottom: solid 2px $yellow-color;
            strong {
                font: normal 20px 'Gotham-Book';
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    margin: 0 0 0 38px;
                    a {
                        font: normal 20px 'Gotham-Bold';
                        color: $yellow-color;
                        text-decoration: none;
                        text-transform: capitalize;
                    }
                }
            }
        }
        #questions {
            h5 {
                margin: 0 0 39px;
                font: normal 28px 'Gotham-Bold';
                text-transform: uppercase;
            }
            a {
                color: #000;
                text-decoration: none;
                font: normal 16px 'Gotham-Bold';
                justify-content: space-between;
                span {
                    align-self: center;
                    background: url('../img/nav-btns.png') 0 0;
                    height: 14px;
                    width: 26px;
                    margin: 0 0 0 60px;
                    &.nav-down {
                        background-position: 0 0;    
                    }
                    &.nav-up {
                        background-position: 0 -16px;
                    }
                }
            }
            p {
                color: #000;
                display: none;
                margin: 0;
                font: normal 16px 'Gotham-Book';
            }
            li {
                padding: 39px 0 0;
                .inner-list {
                    li {
                        padding: 35px 0;
                        border-bottom: solid 1px #ccc;
                        &.open {
                            padding: 34px 0 35px;
                            border-bottom: solid 1px $yellow-color;
                            border-top: solid 1px $yellow-color;
                            p {
                                display: block;
                                margin: 38px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #faq {
        header {
            padding: 25px 0 26px;
            h3 {
                font-size: 18px;
            }
            p {
                display: none;
            }
        }
        #content {
            padding: 10px 0;
            div {
                flex-wrap: wrap;
                padding-left: 10px;
                padding-right: 10px;
                strong {
                    font-size: 18px;
                    width: 100%;
                    margin: 0 0 10px;
                }
                ul {
                    li {
                        margin: 0 10px 0 0;
                        a {
                            font-size: 18px;
                            display: block;
                            padding: 5px;
                        }
                    }
                }
            }
            #questions {
                padding: 0 10px;
                h5 {
                    font-size: 20px;
                    margin: 0;
                }
                li {
                    a {
                        strong {
                            width: calc(#{100%} - 50px);
                        }
                        span {
                            margin: 0;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
    }
}