@media screen and (max-width: 767px) {
    .ng-image-gallery {
        .prev,
        .next,
        .destroy-icons-container {
            z-index: 5;
            .close {
                z-index: 5;
            }
            .index-sum {
                position: relative;
                z-index: 5;
            }
        }
        .galleria {
            left: 0 !important;
            right: 0 !important;
            width: 100%;
            .img-container {
                max-width: 100% !important;
            }
            section {
                padding: 15px 30px !important;
                left: 0;
                strong:first-child {
                    font-size: 16px !important;
                }
                h4 {
                    font-size: 18px !important;
                }
                p {
                    font-size: 15px !important;
                }
                hr {
                    margin: 15px 0 !important;
                }
                .like {
                    strong:first-child {
                        display: none !important;
                    }
                    @media (orientation: portrait) {
                        .horizontal-list {
                            flex-wrap: wrap;
                            margin: 0 0 0 10px;
                            li:first-child {
                                margin: 0 0 10px !important;
                            }
                        }
                    }
                }
                .share {
                    i {
                        display: none !important;
                    }
                }
                @media (orientation: portrait) {
                    .share {
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
    @media (orientation: landscape) {
        .ng-image-gallery {
            .galleria {
                .img-container {
                    max-width: 50% !important;
                }
                section {
                    padding: 15px 50px !important;
                    top: 0 !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}