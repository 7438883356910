@import "fonts";
@import "variables";
nav.navbar.navbar-yelon {
  background-color: white;
  border: none;
  border-top: $thick-border;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
  color: black;
  height: $navbar-height;
  margin-bottom: 0;
  .container-fluid {
    button .icon-bar {
      background: $hamburger-color;
    }
    .navbar-header {
      nav {
        display: flex;
        margin: 10px 0 0 19px;
        height: 30px;
        float: left;
        .link {
          background: #f0f0f0;
          border-right: solid 10px #f4cd49;
          padding: 0 10px;
          font: normal 14px/30px "Gotham-Bold";
          text-transform: uppercase;
          color: #000;
          text-decoration: none;
          margin: 0 9px 0 0;
          &:last-child {
            margin: 0;
          }
        }
        &.icons {
          left: 19px;
          a {
            color: #000;
            text-decoration: none;
            font-size: 22px;
            line-height: 30px;
            padding: 0 5px;
            margin: 0 10px 0 0;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    .navbar-collapse {
      padding-left: 0;
      padding-right: 0;
      .navbar-nav {
        >.dropdown {
          font-family: Gotham-Book;
          letter-spacing: 1px;
          i.glyphicon.dropdown-indicator {
            &:before {
              content: "\e114";
            }
            font-size: 16px;
          }
          &.open {
            i.glyphicon.dropdown-indicator:before {
              content: "\e113";
            }
          }
          >a {
            align-items: center;
            display: flex;
            flex-direction: row;
            text-align: left;
            &.btn-filter {
              font-size: 15px;
            }
            >span {
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              white-space: nowrap;
              width: 100%;
            }
          }
        }
        >li {
          align-items: center;
          display: flex;
          height: $navbar-height - 10px;
          >a:not(.btn) {
            color: black;
            padding: 3px 10px;
          }
          >a.search {
            &:hover,
            &:focus,
            &:active {
              background: #fff;
              color: $yellow-color;
            }
          }
          >.dropdown-menu {
            border: none;
            left: 0;
            padding: 0;
            right: 20px;
            top: 38px;
            >li {
              background-color: #d1d1d1;
              color: #fff;
              font-size: 15px;
              font-family: Gotham-Book;
              letter-spacing: 1px;
              padding: 5px 10px;
              text-transform: uppercase;
              &:focus,
              &:hover {
                opacity: .8;
              }
              >label {
                margin: 0;
                width: 100%;
                >input {
                  display: none;
                }
              }
            }
          }
        }
        >li.switch-to {
          cursor: pointer;
          width: 83px;
          word-break: break-all;
          >a {
            align-items: center;
            color: white;
            display: flex;
            font-size: 14px;
            line-height: 15px;
            height: 100%;
            padding: 0;
            width: 100%;
            &:hover {
              opacity: 0.7;
            }
          }
          &-child {
            background-color: $yellow-color;
            .glyphicon.glyphicon-log-out {
              top: 0;
            }
            strong {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 10px 0 0;
              line-height: 12px;
            }
          }
          &-adult {
            background-color: black;
          }
        }
        .glyphicon {
          font-size: 30px;
        }
        &.reflector-animate {
          li.dropdown:nth-of-type(1) a {
            background-color: #d1d1d1;
            animation: reflector 1.5s ease;
          }
          li.dropdown:nth-of-type(2) a {
            background-color: #d1d1d1;
            animation: reflector 1.5s ease 1.5s;
          }
          li.dropdown:nth-of-type(3) a {
            background-color: #d1d1d1;
            animation: reflector 1.5s ease 3s;
          }
        }
      }
    }
    .navbar-brand {
      margin: 0;
      padding: 5px 0 0;
      >img {
        height: 90%;
      }
    }
  }
}

@keyframes reflector {
  from {
    background-color: #d1d1d1;
  }
  to {
    background-color: $yellow-color;
  }
}