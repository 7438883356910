.body-overflow-hidden {
  overflow: hidden !important;
}

.ng-image-gallery {
  outline: none;
  * {
    outline: none;
  }
  .ng-image-gallery-modal {
    position: fixed;
    z-index: 5000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    // Animate when visible
    transition: opacity 0.3s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    // ngAnimate classes
    &.ng-hide-add {
      opacity: 1;
    }
    &.ng-hide-add-active {
      opacity: 0;
    }
    &.ng-hide-remove {
      opacity: 0;
    }
    &.ng-hide-remove-active {
      opacity: 1;
    }
    // Gallery backdrop view
    .ng-image-gallery-backdrop {
      background-color: rgba(0, 0, 0, 0.95);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    // Gallery content view
    .ng-image-gallery-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      .destroy-icons-container {
        position: absolute;
        right: 10px;
        top: 20px;
        .index-sum {
          color: rgba(255, 255, 255, 1);
          h1 {
            font: normal 22px 'Gotham-Book';
          }
        }
        .close {
          &:hover {
            transition: opacity 0.3s ease-in-out;
            opacity: 0.2;
          }
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-image: url("/assets/img/close.svg");
          }
          position: relative;
          display: inline-block;
          width: 30px;
          height: 30px;
          overflow: hidden;
          cursor: pointer;
          text-decoration: none;
          color:#fff;
          vertical-align: bottom;
          z-index: 1;
          opacity: 1;
        }
      }
      .prev,
      .next {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        width: 50px;
        height: 50px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        cursor: pointer;
        &.bubbles-on {
          top: calc((100% - 50px) / 2);
        }
        @media screen and (max-width:600px) {
          margin-top: -15px;
          width: 30px;
          height: 30px;
        }
      }
      .prev {
        left: 0px;
        background-image: url(/assets/img/prev.svg);
      }
      .next {
        right: 0px;
        background-image: url(/assets/img/next.svg);
      }
      .galleria {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 80px;
        right: 80px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .image-text {
          color: rgba(255, 255, 255, 1);
        }
        .galleria-images {
          position: relative;
          flex: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .img-container {
            position: relative;
            max-width: 70%;
            .galleria-image {
              margin: auto;
              max-width: 100%;
              max-height: 100vh;
              // Animate when visible
              backface-visibility: hidden;
              -webkit-backface-visibility: hidden;
              // Prevent user selection and highlighting
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }
            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.8);
              z-index: 1;
            }
          }
          @import "gallery_components/box_info";
          /*****************************************************/
          // image fadeup animation
          &.img-anim-fadeup {
            .galleria-image {
              &.ng-enter {
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
                opacity: 0;
                transform: scale(0.98);
              }
              &.ng-enter-active {
                opacity: 1;
                transform: scale(1);
              }
              &.ng-leave-active {
                opacity: 0;
              }
            }
          }
        }
      }
    }
    // Image loading animation view
    .ng-image-gallery-loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // Spinner from http://tobiasahlin.com/spinkit/
      .spinner {
        flex: none;
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 10px;
        >div {
          background-color: #fff;
          height: 100%;
          width: 7px;
          margin: 0 3px 0 0;
          display: inline-block;
          -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
          animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }
        .rect2 {
          -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
        }
        .rect3 {
          -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s;
        }
        .rect4 {
          -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
        }
        .rect5 {
          -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
        }
      }
    }
  }
}

// Spinner from http://tobiasahlin.com/spinkit/
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4)
  }
  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}


/*********************************************/

@keyframes revolve-in-forward {
  0% {
    opacity: 0;
    transform: scale(0, 0) translateX(0px);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5, 0.5) translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) translateX(0px);
  }
}

@keyframes revolve-out-forward {
  50% {
    opacity: 0.5;
    transform: scale(0.5, 0.5) translateX(100%);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0) translateX(0px);
  }
}

@keyframes revolve-in-backward {
  0% {
    opacity: 0;
    transform: scale(0, 0) translateX(0px);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5, 0.5) translateX(100%);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) translateX(0px);
  }
}

@keyframes revolve-out-backward {
  50% {
    opacity: 0.5;
    transform: scale(0.5, 0.5) translateX(-100%);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0) translateX(0px);
  }
}