@import "variables";
.tab--chat {
  border: $thick-border;
  border-bottom: none;
  bottom: 0;
  font-size: 12px;
  font-weight: normal;
  right: 50px;
  text-transform: none;
  top: auto;
  width: 330px;
  z-index: 3;
  transform: translateZ(1000px); // safari overlap bug fix
  .chat--header {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px;
    &:focus,
    &:active {
      outline: none;
    }
    .title {
      font: normal 20px 'Gotham-Bold';
    }
    .btn {
      font-family: 'Gotham-Bold';
      margin-left: 10px;
    }
    div {
      span {
        font: normal 12px 'Gotham-Book';
      }
    }
    i.glyphicon {
      margin-right: 10px;
      &:first-of-type {
        margin-left: auto;
      }
      &.glyphicon-time {
        font-size: 30px;
      }
      &.glyphicon-chevron-up {
        font-size: 16px;
      }
    }
  }
  .chat--body {
    background-color: $chat-background;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    position: relative;
    #closed-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: hideClosedImage 2s linear 2s;
      animation-fill-mode: forwards;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: $chat-background;
        img {
          width: 75%;
        }
      }
    }
    .typing-indicator {
      background-color: $chat-typing-background;
      border-radius: 0 5px 5px 5px;
      bottom: 0;
      color: white;
      font-style: italic;
      margin: 10px 0;
      max-width: 75%;
      min-height: 30px;
      padding: 10px;
      position: relative;
    }
    .message-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .message {
        background-color: white;
        border-radius: 5px 0 5px 5px;
        margin: 10px 0;
        max-width: 75%;
        min-height: 30px;
        padding: 10px;
        white-space: pre-wrap;
        word-break: break-word;
        hyphens: auto;
      }
      &.operator {
        align-items: flex-start;
        &.system {
          .message {
            background-color: $yellow-color;
            color: black;
            cursor: pointer;
          }
        }
        .message {
          background-color: $chat-operator-background;
          border-top-right-radius: 5px;
          border-top-left-radius: 0;
          color: white;
        }
      }
      .time {
        color: white;
      }
    }
    &--overlay {
      background-color: $chat-overlay-color;
      bottom: 0;
      color: white;
      left: 0;
      padding-top: 95px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      word-break: normal;
      &.connecting {
        z-index: 1;
        img {
          width: 100%;
        }
      }
      &.rating-container {
        position: relative;
        padding-top: 5px;
      }
      .smiley-container {
        margin: 10px;
        display: flex;
        justify-content: center;
        div {
          label.rate {
            display: inline-block;
            height: 30px;
            margin-left: 7px;
            margin-right: 7px;
            width: 30px;
            >img {
              width: 100%
            }
            input {
              display: none;
            }
          }
        }
      }
      .btn-sendrating {
        background-color: $chat-send-rating-color;
        margin: 10px;
        padding: 10px;
        width: 90%;
        color: rgb(0, 61, 80);
        &:hover,
        &:focus {
          background-color: $chat-send-rating-hover-color;
          color: white;
        }
      }
    }
    #emoji-picker {
      height: auto;
      width: 100%;
      background: #f3f3f3;
      position: fixed;
      z-index: 1;
      left: 0;
      bottom: 45px;
      display: block;
      padding: 25px 26px;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 0 10px 0 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  .chat--input {
    .textarea {
      border: none;
      height: 45px;
      padding: 10px;
      resize: none;
      width: 100%;
      overflow-y: auto;
      &:focus {
        border: none;
        outline: none;
      }
      img {
        width: 22px;
        height: auto;
      }
    }
    .input-group-addon {
      background-color: white;
      color: $chat-send-color;
      &.disabled {
        background-color: rgb(235, 235, 228);
        color: #bbb;
        pointer-events: none;
      }
      .emoji-btn {
        margin: 0 5px 0 0;
      }
    }
  }
}

@keyframes hideClosedImage {
  from {left: 0;}
  to {left: 500px;}
}