@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	#article-page {
        section {
            .content {
                .type-icon {
                    width: 25px;
                    margin-right: 5px;
                }
            }
        }
    }
    #emoji-picker {
        position: absolute !important;
        bottom: 0 !important;
    }
    .custom-modal.modal {
        display: block !important;
        top: 25% !important;
    }
}