.like {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    strong:first-child {
        width: 345px;
    }
    ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        .icon {
            float: left;
            opacity: 0.5;
            &.like {
                background-position: 0 -87px;
                height: 50px;
                width: 44px;
            }
            &.unlike {
                background-position: -58px -87px;
                height: 50px;
                width: 43px;
            }
            &:hover {
                transition: opacity 0.3s ease-in-out;
                opacity: 1;
            }
            &.voted {
                opacity: 1;
                cursor: default;
            }
        }
        li {
            display: flex;
            strong {
                margin: 0 10px 0 9px;
                align-self: center;
                color: #f4cd49;
                font-family: 'Gotham-Medium';
                font-size: 16px;
                display: inline-block;
                span {
                    float: left;
                }
                &.unlike-bubble {
                    color: #000;
                    font-family: 'Gotham-Book';
                }
            }
            i {
                align-self: center;
                font-family: 'Gotham-Medium';
            }
        }
        li:first-child {
            margin: 0 40px 0 0;
            color: #f4cd49;
        }
    }
}