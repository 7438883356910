@import "fonts";
@import "variables";
body {
  background-color: #f3f3f3;
  overflow: hidden;
}

.header {
  background-color: white;
  height: $navbar-height;
}

.wrapper {
  height: calc(100% - #{$navbar-height});
  overflow: auto;
  .app-download {
    position: fixed;
    bottom: 0;
    z-index: 1;
    display: none;
    justify-content: space-around;
    align-items: center;
    border: solid 5px $yellow-color;
    border-bottom: none;
    background: #fff;
    width: 100%;
    padding: 8px 3px;
    p {
      font: normal 10px 'Gotham-Book';
      margin: 0;
      strong {
        display: block;
        font: normal 12px 'Gotham-Bold';
      }
    }
    /*.icon-store {
      background: url("../img/store_sprite_2.png") 0 0 no-repeat;
      height: 33px;
      &.play {
        background-position: 0 0;
        width: 102px;
      }
      &.apple {
        background-position: -111px 0;
        width: 95px;
      }
      &.close-btn {
        background-position: -217px 0;
        height: 18px;
        width: 18px;
      }
    }*/
    .icon-store {
      height: 40px;
      &.play {
        background: url("../img/store_android.png") 0 0 no-repeat;
        background-size: contain;
        width: 135px;
      }
      &.apple {
        background: url("../img/store_ios.jpg") 0 -1px no-repeat;
        background-size: contain;
        width: 116px;
      }
      &.close-btn {
        background: url("../img/store_sprite_2.png") -217px 0 no-repeat;
        height: 18px;
        width: 18px;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}

body {
  .wrapper {
    .container {
      touch-action: initial !important;
    }
  }
}

body#home-page {
  .wrapper {
    overflow-x: hidden;
    .container {
      width: auto;
      max-width: 1170px !important;
    }
  }
}

body.adult {
  .container-fluid {
    display: flex;
    padding: 0 0 0 15px;
  }
  .navbar-header {
    flex-grow: 1;
  }
  .navbar-text {
    text-align: center;
    margin-right: 30px;
    font: normal 18px "Gotham-Bold";
    color: #825e37;
    flex-grow: 2;
  }
  #yelon-navbar {
    display: flex;
    flex-grow: 1;
    .navbar-right {
      float: none;
      margin: 0;
    }
  }
}

.short-desc {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
  margin-bottom: 36px;
  word-wrap: break-word;
}

.tab {
  display: none;
  background-color: white;
  border-top: $thick-border;
  //box-shadow: 0 0 5px 5px #c6c6c6;
  box-shadow: 0 0 2px 1px #f3f3f3;
  font-weight: bold;
  position: fixed;
  text-transform: uppercase;
  top: calc(50% - #{$navbar-height});
  width: 30px;
  word-break: break-all;
  a {
    color: black;
    display: block;
    height: 100%;
    padding: 6px 0;
    text-align: center;
    >img {
      display: block;
      margin: 0 auto;
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  &--about-us {
    left: 0;
    transform: translateZ(1000px); // safari overlap bug fix
    z-index: 3;
  }
  &--exit {
    display: block;
    right: 0;
    transform: translateZ(1000px); // safari overlap bug fix
    z-index: 3;
  }
  &--book {
    display: block;
    right: 0;
    transform: translateZ(1000px); // safari overlap bug fix
    z-index: 3;
    margin: 110px 0 0;
  }
  &--social {
    background-color: $yellow-color;
    border: none;
    bottom: 0;
    color: white;
    height: 40px;
    left: 50px;
    top: auto;
    width: auto;
    z-index: 3;
    >ul {
      list-style: none;
      margin: 0;
      padding: 0 10px;
      >li {
        display: inline-block;
        height: 45px;
        width: 40px;
        >a {
          background-image: url(../img/social_sprite.png);
          &.facebook {
            background-position: 0 0;
          }
          &.instagram {
            background-position: -34px 0;
          }
          &.twitter {
            background-position: -70px 0;
          }
        }
      }
    }
  }
  &.opened-chat {
    display: block !important;
  }
}

body#home-page {
  .tab {
    display: block;
  }
}

//Spinner
.spinner-container {
  background-color: white;
  height: calc(100% - 58px);
  min-height: 200px;
  position: absolute;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 58px;
  &__spinner {
    @include center-with-absolute;
    background: url('../img/spinner.gif') no-repeat center;
    width: 225px;
    background-size: contain;
  }
}

// header
@import "navbar_yelon";
.btn {
  &.btn-header {
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px !important;
  }
  &.btn-white {
    background: white;
    border: none;
    box-shadow: none;
  }
  &.btn-filter {
    border: none;
    box-shadow: none;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    margin-right: 20px;
    padding: 5px 10px;
    text-transform: uppercase;
    width: auto;
    span {
      margin: 0 5px 0 0;
    }
  }
  &.btn-grey {
    background-color: #d1d1d1;
    color: #fff;
    padding: 5px 10px;
  }
  &.btn-yellow {
    background-color: $yellow-color;
    color: #fff;
    padding: 5px 10px;
  }
  &.btn-chat-close {
    background-color: #d1d1d1;
    color: #fff;
    padding: 5px 10px;
    &:active,
    &:focus,
    &:hover {
      background-color: $yellow-color;
    }
  }
}

// Addendum to Safari workaround for rotateY
.tooltip {
  transform: translateZ(1000px);
}

@import "modal";
@import "tiles";
@import "article";
@import "mobile_article";
@import "mobilemenu";
@import "gyik_pages";
@import "faq";
@import "imageGallery";
@import "disclaimer";
@import "mobile_gallery";
@import "search";
@import "chat";
@media screen and (max-width: 768px) {
  .navbar-brand {
    padding-left: 10px !important;
  }
  .tab--social,
  .tab--about-us,
  .tab--exit {
    display: none;
  }
}
@import "ie";