@import "fonts";
@import "variables";
.wrapper {
    width: auto;
}

#article-page {
    .container {
        padding: 0;
        width: 100%;
    }
    &.adult {
        #article-type,
        article {
            border-left-color: #f4cd49 !important;
        }
        .marked {
            border-right-color: #f4cd49 !important;
        }
    }
    .wrapper {
        overflow-x: hidden;
    }
}

#article {
    position: relative;
    width: 100%;
    padding: 0;
    background: #FFF;
    font-family: 'Gotham-Bold';
    .bg-color {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 300px;
    }
    .icon {
        background: url('../img/sprite.png') 0 0;
        width: 0;
        height: 0;
    }
    strong {
        font-weight: normal;
    }
    i {
        font-style: normal;
    }
    ul.horizontal-list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin: 0;
            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    section {
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        .content {
            width: 1062px;
            position: relative;
            margin: 0 auto;
            #article-type {
                display: flex;
                //border-left: solid 10px #f47d5c;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) inset;
                width: 206px;
                background: #FFF;
                height: 34px;
                align-items: center;
                color: #f47d5c;
                li {
                    font-size: 20px;
                    &:last-child {
                        font-size: 16px;
                    }
                    &.type-icon {
                        svg {
                            height: 20px;
                            margin-top: 5px;
                            margin-left: 10px;
                            width: auto;
                        }
                    }
                }
                .pen {
                    background-position: 0 -5px;
                    height: 17px;
                    width: 15px;
                    margin: 0 0 0 10px;
                }
                .divider {
                    border-left: solid 1px #e5e5e5;
                    height: 13px;
                    margin: 0 10px 0 9px;
                }
            }
            h1,
            h3 {
                text-align: center;
                background: rgba(255, 255, 255, 0.7);
                width: 690px;
                margin: 25px auto 60px;
                font-size: 28px;
                padding: 20px 0;
            }
            h1 {
                color: #004a60;
            }
            h3 {
                margin: 20px 0;
                padding: 0;
                text-align: left;
                font-size: 24px !important;
            }
            article {
                border-left: solid 10px #f47d5c;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                background: #fff;
                padding: 60px 60px 0 60px;
                header {
                    h2 {
                        font-size: 18px;
                        font-family: 'Gotham-Medium';
                    }
                }
                p {
                    margin: 20px 0;
                    font-size: 16px;
                    font-family: 'Gotham-Book';
                    &.marked {
                        background: #f3f3f3;
                        position: relative;
                        width: 1090px;
                        //border-right: solid 10px #f47d5c;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                        right: 80px;
                        padding: 40px 91px 36px 82px;
                        font-size: 16px;
                        font-family: 'Gotham-BookItalic';
                    }
                    ul,
                    ol {
                        padding: 0 0 0 60px;
                        li {
                            margin: 0 0 10px;
                        }
                    }
                    ol {
                        li {
                            padding: 0 0 0 10px;
                        }
                    }
                    ul {
                        list-style: disc;
                    }
                    strong {
                        font-family: 'Gotham-Bold';
                    }
                    i {
                        font-family: 'Gotham-BookItalic';
                    }
                    a {
                        color: $yellow-color;
                    }
                }
                img:not(.type-indicator),
                .embed-responsive {
                    margin: 20px 0 0;
                }
                .embed-responsive-16by9 {
                    padding: 0;
                    height: 100%;
                    iframe {
                        position: static;
                        height: 60vh;
                        width: 100%;
                    }
                }
                iframe.tumblr-embed, iframe.instagram-media {
                    margin-left: auto !important;
                    margin-right: auto !important;
                    max-width: 542px !important;
                }
                figure {
                    text-align: left;
                    .img-responsive {
                        max-height: 350px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    figcaption {
                        font: normal 16px 'Gotham-BookItalic';
                        text-align: center;
                    }
                }
                hr {
                    margin: 20px 0;
                }
                .tags {
                    display: flex;
                    text-transform: uppercase;
                    font-size: 16px;
                    ul {
                        display: flex;
                        margin: 0 0 0 9px;
                        li {
                            background: #cacaca;
                            margin: 0 2px 0 0;
                            padding: 0 4px;
                            color: #fff;
                            font-size: 14px;
                            font-family: 'Gotham-Medium';
                            line-height: 23px;
                        }
                    }
                }
                .share-and-like {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                }
                @import "common/like_dislike_button";
                div.like {
                    strong {
                        span {
                            background: url("../img/sprite.png") -16px -152px no-repeat;
                            width: auto;
                            height: 33px;
                            line-height: 33px;
                            &.left {
                                background-position: 0 -152px;
                                width: 16px;
                            }
                            &.right {
                                background-position: -67px -152px;
                                width: 7px;
                            }
                        }
                        &.unlike-bubble {
                            span {
                                background-position: -16px -195px;
                                &.left {
                                    background-position: 0px -195px;
                                }
                                &.right {
                                    background-position: -67px -195px;
                                }
                            }
                        }
                    }
                }
                @import "common/facebook_share";
                div.share {
                    align-items: center;
                    strong {
                        margin: 0 0 0 10px;
                        span {
                            background: url("../img/sprite.png") -16px -195px no-repeat;
                            width: auto;
                            height: 33px;
                            line-height: 33px;
                            &.left {
                                background-position: 0px -195px;
                                width: 16px;
                            }
                            &.right {
                                background-position: -67px -195px;
                                width: 7px;
                            }
                        }
                    }
                }
                footer {
                    margin: 0 0 140px;
                    h5 {
                        font-size: 24px;
                        text-transform: uppercase;
                        font-family: 'Gotham-Bold';
                    }
                    .square {
                        display: flex;
                        position: relative;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                        height: 391px;
                        width: 978px;
                        top: 27px;
                        right: 24px;
                        justify-content: space-between;
                        font-size: 16px;
                        strong {
                            background: rgba(255, 255, 255, 0.7);
                        }
                        div {
                            background: #f47d5c;
                            position: relative;
                            img {
                                height: 391px;
                            }
                            span,
                            strong {
                                position: absolute;
                                left: 0;
                                z-index: 1;
                            }
                            span {
                                top: 0;
                            }
                            strong {
                                bottom: 20px;
                                padding: 20px;
                                margin: 0 20px 0 19px;
                            }
                            &.big {
                                font-size: 33px;
                                width: 390px;
                                img {
                                    width: 390px;
                                }
                            }
                            &.middle {
                                width: 195px;
                                strong {
                                    bottom: 11px;
                                    margin: 0 10px 0 9px;
                                    padding: 11px 16px 9px 9px;
                                }
                            }
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            width: 195px;
                            justify-content: space-between;
                            li {
                                background: #f47d5c;
                                height: 195px;
                                position: relative;
                                span,
                                strong {
                                    position: absolute;
                                    left: 0;
                                    z-index: 1;
                                }
                                span {
                                    top: 0;
                                }
                                strong {
                                    bottom: 11px;
                                    margin: 0 10px 0 9px;
                                    padding: 11px 14px 9px 9px;
                                }
                                &.color-bg {
                                    strong {
                                        background: transparent;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                        .icon-container {
                            background-color: #f47d5c;
                            float: left;
                        }
                        .icon {
                            position: static;
                            float: left;
                            &.video {
                                background-position: -49px 0;
                                width: 21px;
                                height: 22px;
                                margin: 9px 11px 11px 11px;
                            }
                            &.article {
                                background-position: -22px 0;
                                width: 19px;
                                height: 22px;
                                margin: 10px 13px 10px 11px;
                            }
                        }
                    }
                    md-grid-tile {
                        .front.tile {
                            figcaption {
                                font-family: 'Gotham-Bold' !important;
                                font-weight: normal !important;
                                text-align: left !important;
                            }
                        }
                        .back.tile {
                            h4 {
                                font-weight: normal !important;
                                font-family: 'Gotham-Book' !important;
                            }
                            p {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}