.share {
    display: flex;
    font-size: 16px;
    .icon.facebook-share {
        background: url('../img/sprite.png') 0 -35px no-repeat;
        height: 40px;
        width: 117px;
        &:hover {
            cursor: pointer;
        }
    }
    strong {
        font-family: 'Gotham-Book';
        font-size: 16px;
        margin: 4px 0 0 10px;
        align-self: center;
        display: inline-block;
        span {
            background: url('../img/sprite.png') -11px -196px no-repeat; 
            height: 32px;
            width: auto;
            float: left;
            &.left {
                background-position: 0 -196px;
                width: 16px;
            }
            &.right {
                background-position: -67px -196px;
                width: 9px;
            }
        }
    }
    i {
        flex-grow: 1;
        text-align: right;
        align-self: center;
        font-size: 18px;
        font-family: 'Gotham-Medium';
    }
}