@import "fonts";
@import "variables";
#pages {
    background: #fff;
    box-shadow: #c6c6c6 0 0 5px 5px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    p {
        margin: 0;
    }
    header {
        background: #004a60;
        color: #fff;
        text-align: center;
        padding: 50px 0 57px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
            text-transform: uppercase;
            margin: 0;
            font: normal 28px 'Gotham-Bold';
        }
        p {
            margin: 18px 70px 0;
            font: normal 16px 'Gotham-Book';
        }
    }
    .nav-tabs.nav-justified {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }
    .uib-tab {
        display: block;
        width: 100%;
        float: none;
        a {
            background: #336473;
            border: none;
            border-radius: 0;
            text-transform: uppercase;
            color: #fff;
            font: normal 20px 'Gotham-Bold';
        }
        &.active {
            a {
                background: #fff;
                color: #336473;
            }
        }
    }
    #helpers,
    #app {
        display: flex;
        padding: 60px 0 102px;
        article {
            margin: 0 10px 0 38px;
            width: calc(#{50%} - 38px);
            font: normal 16px 'Gotham-Book';
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                margin: 10px 0 0;
                ul {
                    list-style: disc;
                    li {
                        margin: 10px 0 0;
                    }
                }
            }
            p.bold:first-child {
                font-family: 'Gotham-Bold';
            }
        }
        .img-container {
            width: 50%;
        }
        .app-store {
            background: #f5f5f5;
            padding: 48px 56px 54px 39px;
            margin: 41px 0 0;
            p {
                font: normal 22px "Gotham-Bold";
                color: #000;
                margin: 0 0 39px;
            }
            ul {
                display: flex;
                .icon-store {
                    background: url("../img/store_sprite.png") 0 0;
                    width: 0;
                    height: 70px;
                    display: block;
                    &.play {
                        width: 215px;
                        margin: 0 19px 0 0;
                    }
                    &.apple {
                        width: 198px;
                        background-position: -235px 0;
                    }
                }
            }
        }
    }
    #ours {
        display: flex;
        padding: 60px 0 102px;
        .img-container {
            width: 50%;
        }
        article {
            width: calc(#{50%} - 104px);
            margin: 0 64px 0 40px;
            font: normal 16px 'Gotham-Book';
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                margin: 10px 0 0;
                ul {
                    list-style: disc;
                    li {
                        margin: 10px 0 0;
                    }
                }
            }
            p.bold:first-child {
                font-family: 'Gotham-Bold';
            }
            span {
                text-align: right;
            }
        }
    }
    #app {
        .img-container {
            //display: flex;
            //justify-content: center;
            .img-responsive {
                margin: 0 auto;
            }
        }
        article {
            align-self: flex-start;
            margin-right: 39px;
        }
    }
}

@media screen and (max-width: 767px) {
    #pages {
        header {
            padding: 25px 0 26px;
            display: none;
            h3 {
                font-size: 18px;
            }
            p {
                font-size: 13px;
            }
        }
        .nav-tabs.nav-justified {
            flex-wrap: wrap;
            .nav-item {
                margin: 0;
                a {
                    margin: 0;
                    font-size: 16px;
                }
                &.active a {
                    background: $yellow-color;
                }
            }
        }
        #helpers,
        #ours,
        #app {
            flex-wrap: wrap;
            padding-top: 38px !important;
            .img-container {
                width: 100%;
                order: 1;
            }
            article {
                margin: 0;
                padding: 5px 5%;
                width: auto;
                order: 2;
                p {
                    font-size: 17px;
                    line-height: 22px;
                }
                .app-store ul {
                    flex-wrap: wrap;
                    li:last-child {
                        margin: 10px 0 0;
                    }
                }
            }
        }
    }
}