@import "variables";
@import "fonts";
#disclaimer-container {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: auto;
    top: 60px;
    left: 0;
    z-index: 5;
    color: #fff;
    .btn-danger {
        background: $yellow-color;
        border: none;
        color: #000;
        font: normal 18px 'Gotham-Bold';
    }
    div.disclaimer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 14px 0;
        p {
            margin: 0 19px 0 0;
            font: normal 18px 'Gotham-Medium';
            span {
                color: $yellow-color;
            }
        }
        img {
            margin: 0 0 0 18px;
            &:first-child {
                margin: 0 20px 0 0;
            }
        }
        @media screen and (max-width:767px) {
            flex-wrap: wrap;
            padding: 0 10px;
            img {
                order: 2;
                &:first-child {
                    order: 0;
                }
            }
            p {
                order: 1;
                width: calc(100% - 136px);
                text-align: center;
                margin: 0;
            }
            .btn-danger {
                order: 3;
                margin: 15px 0 0;
            }
        }
    }
    div.site-help {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 37px 0 40px;
        h4 {
            color: $yellow-color;
            text-transform: uppercase;
            font: normal 20px 'Gotham-Bold';
        }
        p {
            max-width: 628px;
            text-align: center;
            margin: 21px 0 30px;
            font: normal 18px 'Gotham-Medium';
        }
    }
}