@import "variables";
.custom-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    .modal-dialog {
        width: 40%;
        @media screen and (max-width: 767px) {
            width: 90%;
        }
    }
    .modal-content {
        .modal-header {
            border: none;
            text-align: center;
            display: flex;
            justify-content: center;
            h3 {
                border-bottom: solid 2px $yellow-color;
                margin: 0;
                padding: 0 0 3px;
            }
        }
        .modal-body {
            text-align: center;
        }
        .modal-footer {
            border: none;
            display: flex;
            justify-content: space-between;
            .btn {
                flex-basis: 100px;
            }
            .btn-warning {
                background: #f0a112;
            }
            .btn-primary {
                background: #565656;
            }
        }
    }
}