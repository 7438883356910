@media screen and (max-width: 925px) {
    #article-page {
        .container {
            padding: 0 15px;
            width: auto;
        }
        .wrapper {
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
        }
    }
    .content {
        padding: 0;
    }
    #article {
        height: auto;
        section {
            //background-color: #fff !important;
            padding: 0;
            .content {
                width: auto;
                //background-color: #fff;
                #article-type {
                    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                    width: 210px;
                }
                h3 {
                    margin: 0;
                    padding: 30px 18px;
                    width: auto;
                    background: transparent;
                    text-align: left;
                    font-size: 20px;
                    line-height: 20px;
                    color: #004a60;
                }
                article {
                    padding: 0;
                    border: none !important;
                    box-shadow: none;
                    .iframe2-container {
                        overflow:auto;
                        -webkit-overflow-scrolling:touch;
                        width:100%;
                        height:350px;
                    }
                    header {
                        font-size: 16px;
                        line-height: 16px;
                        padding: 0 5%;
                    }
                    p {
                        padding: 0 5%;
                        margin: 32px 0;
                        font-size: 17px !important;
                        line-height: 22px;
                        >p {
                            padding: 0;
                        }
                    }
                    .marked {
                        width: auto !important;
                        padding: 19px 5% !important;
                        right: 0 !important;
                        border-right: none !important;
                        border-left: solid 2px #f47d5c;
                    }
                    .tags {
                        flex-wrap: wrap;
                        strong {
                            font-size: 20px;
                            width: 100%;
                            padding: 0 10px;
                            margin: 0 0 10px;
                        }
                        ul {
                            flex-wrap: wrap;
                            li {
                                font-size: 18px !important;
                                line-height: 28px !important;
                                margin: 0 5px 5px 0 !important;
                            }
                        }
                    }
                    .like {
                        margin: 0 0 10px;
                        .horizontal-list {
                            flex-wrap: wrap;
                            margin: 0;
                            justify-content: center;
                            li {
                                margin: 0;
                                &:first-child {
                                    margin: 0 0 10px;
                                }
                            }
                        }
                    }
                    hr {
                        margin: 15px 0;
                    }
                    footer {
                        h5 {
                            font-size: 22px;
                            text-indent: 10px;
                            margin: 10px 0 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #article {
        section {
            background-size: 250%;
            h1 {
                padding: 10px !important;
                font-size: 22px !important;
                width: calc(100% - 20px) !important;
            }
            article {
                padding: 1px 0 0 !important;
            }
        }
    }
}